<template>
  <div class="navigation">
    <div class="navigation__item" v-for="(item,index) in items" :key="index">
      <atom-link :to="item.link" class="navigation__item-link" variant="gray sm medium"
        >{{item.name}}</atom-link>
      <atom-icon class="navigation__item-icon" icon="arrow-right-s"></atom-icon>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
      required:true
    },
  }
};
</script>

<style lang="scss" scoped>
.navigation {
  @apply flex items-center my-3 gap-4;
  &__item{
    @apply flex gap-4;
    &:last-child{
      .navigation__item-icon{
        @apply hidden;
      }
    }
    &-link {
    }
    &-icon {
      @apply fill-[#6B7280] w-[22px];
    }
  }
}
</style>
